<ion-toolbar class="company-header" dir="ltr">
  <ion-buttons slot="start">
      <ion-menu-button *ngIf="auth.company && auth.company.company_status_override != 9">
          <ion-icon src="assets/images/ic_drawer.svg"></ion-icon>
      </ion-menu-button>
  </ion-buttons>
  <ion-title class="brand">
      <img src="assets/logo.png" />
  </ion-title>
  <ion-buttons slot="end">
    <ion-button fill="clear" class="card-header buttons-end-slot" (click)="loadPage('account')" color="dark">

        <span class="initials" [style.backgroundColor]="bgColorWhenNoPhoto">{{initials}}</span>
        <!--*ngIf="!auth.profile_photo"
            <ion-avatar class="pull-left"  slot="start"  *ngIf="auth.profile_photo">
            <img src="{{ awsService.cloudinaryUrl }},w_40,h_40,c_thumb,g_face/agent-photo/{{ auth.profile_photo }}" class="circle" width="30" />
        </ion-avatar>
        -->
        <span class="name">{{ auth.profile_name }}</span>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
