<div *ngIf="auth.company" dir="ltr" class="menu-wraper">

  <ion-toolbar class="toolbar" *ngIf="auth.company">
    <div class="company-logo">
      <ion-avatar [class.no-logo]="!auth.company.company_logo">
        <ion-icon *ngIf="!auth.company.company_logo" class="no-logo main-logo" name="business">
        </ion-icon>
        <ion-img src="{{ awsService.cloudinaryUrl + auth.company.company_logo }}" class="company-logo"
          *ngIf="auth.company.company_logo" (ionError)="auth.company.company_logo = null;"></ion-img>
      </ion-avatar>

    </div>
    <ion-label class="heading">
      {{ auth.company.company_name }}
    </ion-label>
  </ion-toolbar>

  <ion-list lines="none" class="pages" (click)="closeMenu()">

    <ion-item [routerLink]="'/company-edit'" routerLinkActive="active">
      <div tabindex="0"></div>
      <ion-icon name="business-outline"></ion-icon>
      <ion-label>
        {{"Company Profile" | translate}}
      </ion-label>
    </ion-item>

    <ion-item [routerLink]="'/company-contacts'" routerLinkActive="active">
      <div tabindex="0"></div>
      <ion-icon name="people-outline"></ion-icon>
      <ion-label>
        {{"Team" | translate}}
      </ion-label>
    </ion-item>

    <ion-item [routerLink]="'/contract-list'" routerLinkActive="active">
      <div tabindex="0"></div>
      <ion-icon name="document-text-outline"></ion-icon>
      <ion-label>
        {{"Contracts" | translate}}
      </ion-label>
    </ion-item>

    <!--
    <ion-item [routerLink]="'/chat-list'" routerLinkActive="active">
      <div tabindex="0"></div>
      <ion-icon name="chatbubbles" mode="ios"></ion-icon>
      <ion-label>
        {{"Chat" | translate}} 
      </ion-label>  
      <ion-badge  *ngIf="totalUnread > 0">{{ totalUnread }}</ion-badge>
    </ion-item>-->
 
    <!--
    <ion-item target="_blank" href="https://dash.plugn.io">
      <div tabindex="0"></div>
      <ion-icon name="arrow-redo-outline"></ion-icon>
      <ion-label>
        {{ "Plugn" | translate}}  
      </ion-label>
    </ion-item>-->

    <ion-item tappable (click)="changeLanguage($event)">
      <div tabindex="0"></div>
      <ion-label *ngIf="translateService.currentLang == 'ar'">English</ion-label>
      <ion-label *ngIf="translateService.currentLang == 'en'">عربى</ion-label>
    </ion-item>

<!--    <ion-item class="ion-activatable ion-focusable" (click)="auth.logout()" lines="none" tappable detail="false">-->
<!--      <div tabindex="0"></div>-->
<!--      <ion-label>logout</ion-label>-->
<!--    </ion-item>-->

  </ion-list>

  <ion-list lines="none" class="companies-list" *ngIf="auth.companies.length > 1">

    <ng-container *ngFor="let model of auth.companies" class="list-header">
      <ion-item *ngIf="model.company_id != auth.company_id" tappable (click)='changeCompany(model)'>
        <div tabindex="0"></div>
        <ion-avatar slot="start" [class.no-logo]="!model.company_logo">
          <ion-icon *ngIf="!model.company_logo" class="no-logo company-logo" name="business">
          </ion-icon>
          <ion-img src="{{ awsService.cloudinaryUrl + model.company_logo }}" class="company-logo"
            *ngIf="model.company_logo" (ionError)="model.company_logo = null;"></ion-img>
        </ion-avatar>
        <ion-label>
          {{ model.company_common_name_en?model.company_common_name_en: model.company_name }}
        </ion-label>
        <!--
            <ion-badge slot="end" color="danger"
                *ngIf="model.notificationsCount > 0 && model.notificationsCount < 9">{{ model.notificationsCount }}
            </ion-badge>
            <ion-badge slot="end" color="danger"
                *ngIf="model.notificationsCount > 0 && model.notificationsCount > 9">9+</ion-badge>-->
      </ion-item>
    </ng-container>

  </ion-list>

  <!--
  <ion-list lines="none" class="currency-list" *ngIf="auth.currencies.length > 1">
    <ion-list-header>Currencies</ion-list-header>
    <ion-select [value]="auth.currency_pref" (ionChange)="setCurrency($event)">
      <ion-select-option [value]="currency.code" *ngFor="let currency of auth.currencies">
        {{ currency.code }}
      </ion-select-option>
    </ion-select>
  </ion-list>  -->

  <!--
  <ion-button fill="clear" class="btn-theme" (click)="changeTheme()">
  
    <ion-icon *ngIf="auth.theme == 'night'" slot="icon-only" name="moon-outline"></ion-icon>
    <ion-icon *ngIf="auth.theme != 'night'" slot="icon-only" name="partly-sunny-outline"></ion-icon>
  </ion-button> -->
</div>
 
