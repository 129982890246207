import { Pipe, PipeTransform, NgZone, ChangeDetectorRef, OnDestroy } from "@angular/core";
//services
import { TranslateLabelService } from "../providers/translate-label.service";


@Pipe({
	name:'timeAgo',
	pure:false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {

    public timer: number;

    constructor(
		public translate: TranslateLabelService,
        public changeDetectorRef: ChangeDetectorRef,
        public ngZone: NgZone
    ) {}

	/**
	 * @param value 
	 * @returns 
	 */
	transform(value: string) {
		this.removeTimer();

    	let GMTDate = new Date();

		let d = (value) ? new Date(value.replace(/-/g, '/') + ' GMT+03:00') : GMTDate['toGMTString']();
		let now = new Date();
		let seconds = Math.round(Math.abs((now.getTime() - d.getTime())/1000));
		let timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;

        this.timer = this.ngZone.runOutsideAngular(() => {
			if (typeof window !== 'undefined') {
				return window.setTimeout(() => {
					this.ngZone.run(() => this.changeDetectorRef.markForCheck());
				}, timeToUpdate);
			}
			return null;
        });

		let minutes = Math.round(Math.abs(seconds / 60));
		let hours = Math.round(Math.abs(minutes / 60));
		let days = Math.round(Math.abs(hours / 24));
		let months = Math.round(Math.abs(days/30.416));
		let years = Math.round(Math.abs(days/365));

		if (Number.isNaN(seconds)){
			return '';
		} else if (seconds <= 45) {
			return this.translate.transform('a few seconds ago');
		} else if (seconds <= 90) {
			return this.translate.transform('a minute ago');
		} else if (minutes <= 45) {
			return this.translate.transform("txt_minutes_ago", { value: minutes });
		} else if (minutes <= 90) {
			return this.translate.transform('an hour ago');
		} else if (hours <= 22) {
			return this.translate.transform("txt_hours_ago", { value: hours });
		} else if (hours <= 36) {
			return this.translate.transform('a day ago');
		} else if (days <= 25) {
			return this.translate.transform("txt_days_ago", { value: days });
		} else if (days <= 45) {
			return this.translate.transform('a month ago');
		} else if (days <= 345) {
			return this.translate.transform("txt_months_ago", { value: months });
		} else if (days <= 545) {
			return this.translate.transform('a year ago');
		} else { // (days > 545)
			return this.translate.transform("txt_years_ago", { value: years });
		}
    }

	ngOnDestroy(): void {
		this.removeTimer();
    }

	public removeTimer() {
		if (this.timer) {
			window.clearTimeout(this.timer);
			this.timer = null;
		}
    }

	public getSecondsUntilUpdate(seconds:number) {
		let min = 60;
		let hr = min * 60;
		let day = hr * 24;
		if (seconds < min) { // less than 1 min, update every 2 secs
			return 2;
		} else if (seconds < hr) { // less than an hour, update every 30 secs
			return 30;
		} else if (seconds < day) { // less then a day, update every 5 mins
			return 300;
		} else { // update every hour
			return 3600;
		}
	}
}

